import { parse, Cookie } from 'set-cookie-parser';

import { RequestError } from '@ha/api/v2/errors';
import type { Query } from '@ha/api/v2/getUser';
import type { Query as QueryUserInformation } from '@ha/api/v2/getUserInformation';
import type { Logger } from '@hbf/log';

import { Cookies as COOKIES } from 'ha/constants/Cookies';

import { Thunk } from 'ha/myredux/types';
import { AppServices } from 'ha/services/getAppServices';

import { AuthLogicActions } from './actions';

const setCookie = (cookies: Cookie[], services: AppServices): void => {
  cookies.forEach(cookie => {
    services.cookie.setCookie(cookie.name as COOKIES, cookie.value, {
      // The `cookies` package used as the client for services.cookie expects maxAge in milliseconds!
      // The spec says max-age is in seconds, so we convert it here.
      maxAge: cookie.maxAge ? cookie.maxAge * 1000 : undefined,
      domain: cookie.domain,
      path: cookie.path,
      expires: cookie.expires,
      httpOnly: cookie.httpOnly,
    });
  });
};

export const fetchUser: (logger?: Logger, referrer?: string) => Thunk =
  (logger?: Logger, referrer?: string) => (dispatch, _, services) => {
    const userQuery: Query = {};
    const userInformationQuery: QueryUserInformation = {
      expand:
        'advertiserBookingsCount,savedPaymentMethodsCount,listingsCount,subscriptionFeatures,freeBookingsUsage',
    };

    if (referrer?.includes('id.housinganywhere.com')) {
      userQuery.initial = true;
      userInformationQuery.initial = true;
    }

    return Promise.all([
      services.apiV2.getUser('me', userQuery),
      services.apiV2.getUserInformation('me', userInformationQuery),
    ])
      .then(
        ([
          { data: user, headers, status: statusUserMe },
          { data: userInformation, status: statusUserMeInformation },
        ]) => {
          // @todo IDP remove after confirming new token refresh system
          if (!process.browser && headers) {
            try {
              const cookies = parse(headers.get('set-cookie') as string);
              setCookie(cookies, services);
            } catch (error) {
              // An error here is not critical, so we just log it to the console.
              // eslint-disable-next-line no-console
              console.error(error);
            }
          }

          logger?.info({
            message: 'fetch_user',
            referrer,
            status: statusUserMe,
            statusUserMeInformation,
          });

          dispatch(
            AuthLogicActions.initUser({
              user,
              userInformation,
            }),
          );
        },
      )
      .catch((error: RequestError) => {
        // @todo IDP remove after confirming new token refresh system
        if (!process.browser && error.headers) {
          try {
            const cookies = parse(error.headers.get('set-cookie') as string);
            setCookie(cookies, services);
          } catch (setCookieError) {
            // eslint-disable-next-line no-console
            console.error(setCookieError);
          }
        }

        logger?.info({
          message: 'fetch_user',
          referrer,
          status: error?.status,
          error,
        });
      });
  };
